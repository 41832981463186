import { css } from "@emotion/react";

const bonoxsStyles = css`
  &.bonoxs {
    * {
      font-family: "Montserrat", sans-serif;
    }

    .ticket-entry-fee-container {
      img {
        transform: none !important;
      }
    }

    .clock-participant-bg-svg,
    .icon-play,
    .btn-container > svg,
    .rank-icon {
      fill: #000000 !important;
    }

    .clock-participant-text,
    .rank-list-container-first,
    .rank-list-container,
    .modal_header,
    .leaderboard-wrapper,
    .prize-list-container,
    .list-group-item,
    .text-header,
    .balance,
    .subtitle-4,
    .header-style,
    .mobile-footer-page .text-center {
      color: #ffff00 !important;
    }

    .clock-participant-text > div > svg {
      fill: #ffff00 !important;
    }

    .button_icon > svg,
    .btn-style,
    .list-heading > .text-style,
    .text-center,
    .redeem,
    .img-sty,
    .background-contain > div > div,
    .name-style,
    .score,
    .prizes-slider-item,
    .prize-calculator-item,
    .list-group-item > div > div,
    .list-group-item > div > div > span,
    .btn-orange > .button-style,
    .btn-orange,
    .white-card,
    .white-card .txt-style,
    .white-card .won-style,
    .white-card .label,
    .dollar {
      color: #000000 !important;
    }

    .active_progress_bottom_curve,
    .modal_header,
    .desktop-loader,
    .game-loading-overlay,
    .modal_body,
    button > .loading-style,
    .recommended-games,
    .leaderboard-body {
      background-color: #000000 !important;
    }

    .desktop-footer {
      .footer-content {
        background-color: #000000 !important;
      }
    }
    .leaderboard-popup-item {
      .gamename-style {
        color: #ffff00 !important;
      }
    }
    .ticket-status,
    .partner-status,
    .bonoxs-ticket {
      box-shadow: #73ff9b -1pt 6pt 5px 0px !important;
    }

    .back-arrow {
      fill: #ffff00 !important;
      stroke: #ffff00 !important;
    }

    .background-contain > div > div,
    .loading-style,
    .unlock-style {
      background-color: #ffff00 !important;
    }

    .square-card,
    .single-game-card > .square-card.active-card,
    .prizes-slider-item,
    .prize-list-container,
    .leaderboard-wrapper,
    .rank-list-container-first,
    .white-card-design {
      background-color: #878787 !important;
    }

    .list-heading > div {
      color: #878787 !important;
    }

    .small_button {
      box-shadow: #ffff00 0px 5px 10px;
    }

    .recommended-tournament-container {
      display: none !important;
    }

    .userprofile-background,
    .progressContainer,
    .rounded-div,
    .ticket-status,
    .partner-status {
      background-color: #73ff9b !important;
    }

    .ticket-status > div > img,
    .img-sty,
    .reward-img,
    .heroes img {
      transform: none !important;
    }

    .partner-status > div > img,
    .img-sty,
    .reward-img,
    .heroes img {
      transform: none !important;
    }

    .active-card > .card-title,
    .square-card > .card-title,
    .list-group-item > div > .prize-amount,
    .list-group-item > div > .prize-amount > span {
      color: #73ff9b !important;
    }

    .token-circle > .img-sty {
      background-color: transparent !important;
    }

    .img-sty {
      padding-bottom: 0 !important;
    }

    .square-card > .action_button {
      bottom: 0.5rem !important;
    }

    .list-group-item > div > .prize-amount > span,
    .helper_text {
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    }

    .ticket-status > div > img,
    .partner-status > div > img {
      width: 2.5rem;
    }

    .ticket-status > div > span,
    .partner-status > div > span,
    .font-play,
    .modal-content > .modal-footer > div > button {
      color: #000000 !important;
    }

    .top-tournament-card > div > div > .img-style {
      max-width: 100%;
    }

    .small-card {
      background-color: #e6e7e8;
    }

    .modal_container_2 {
      .title-md {
        color: #ffffff !important;
      }
    }

    .play-btn-browse {
      svg {
        fill: #000000 !important;
      }
    }

    .second-half {
      .btn-circle-with-icon {
        background: #73ff9b !important;
        box-shadow: 0 12px 17px -7px #73ff9b !important;
      }
    }
    .board-container {
      .label-style {
        background: #73da91 !important;
      }
      .tile {
        fill: #73ff9b !important;
      }
    }

    .game-over-mission-wrapper {
      .missions__item {
        border: 3px solid #73ff9b;
        &.claimed {
          filter: grayscale(0%);
        }
        .play-button {
          color: #000000;
          background: #73ff9b;
          box-shadow: none;
          font-weight: bold;
        }
        .text-claimed {
          color: #73ff9b;
        }
        /* box-shadow: 0px 0px 5px 2px #73ff9b; */
        background: #000000;
        .text-progress {
          color: #ffffff;
        }
        .text-title {
          color: #ffffff;
        }
        .clock-icon {
          fill: #ffffff;
        }
        .text-timer {
          color: #ffffff;
        }
      }
    }

    .recommended-games-container {
      position: relative;
      top: 0;

      .recommended-games {
        padding-top: 0 !important;
      }
    }
  }
`;

export default bonoxsStyles;
