import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { ReactComponent as RewardBg } from "assets/images/wheel/reward-bg.svg";
import { Modal } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useAppSelector } from "redux/Store";

const zIndexModal = 999998;
const zIndexAnimationObject = zIndexModal + 2;

export default function RewardModal({ show, setShowWheel, setPrize, prize, setShowSpinReward, message }: any) {
  const { isDesktop } = useAppSelector(state => state.layout);
  const [startAnim, setStartAnim] = useState(false);

  useEffect(() => {
    if (show) {
      document.body.classList.add("daily-login-reward_modal--backdrop");
    } else {
      document.body.classList.add("daily-login-reward_modal--backdrop");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!show) return;
    setStartAnim(true);
    let headElem = document.getElementsByClassName("navbar-home")[0] as any;
    const insideHeadElem = headElem?.getElementsByClassName("header-style")[0] || null;
    const logosInsideHeadElem = headElem?.getElementsByClassName("navbar-logos")[0] || null;
    if (!isDesktop) {
      try {
        if (logosInsideHeadElem) {
          logosInsideHeadElem.style.visibility = "hidden";
        }
      } catch (e) {
        //
      }
      try {
        if (insideHeadElem) {
          insideHeadElem.classList.add("bg-transparent");
        }
      } catch (e) {
        //
      }
    } else {
      headElem = document.getElementsByClassName("top-bar-container")[0] as any;
    }
    if (headElem) {
      headElem.style.zIndex = zIndexModal + 1;
      headElem.classList.add("bg-transparent");
    }
    setTimeout(() => {
      if (headElem && headElem.style?.zIndex) {
        headElem.style.zIndex = 33;
        headElem.classList.remove("bg-transparent");
        try {
          if (insideHeadElem) {
            insideHeadElem.classList.remove("bg-transparent");
          }
        } catch (e) {
          //
        }
        try {
          if (logosInsideHeadElem) {
            logosInsideHeadElem.style.visibility = "visible";
          }
        } catch (e) {
          //
        }
      }
      setStartAnim(false);
    }, 2500);
  }, [isDesktop, show]);

  const getAnimImg = () => {
    const animationDuration = 0.8;
    const animationDelayRatio = 0.09;

    const priceIsTicket = prize?.type === "STATUS_POINTS" || "TICKET";
    const priceIsCoin = prize?.type === "TOKEN" || "COIN";
    const priceIsPhysical = prize?.type === "PHYSICAL_REWARD";
    const priceIsPartnerPoint = prize?.type === "PARTNER_POINT";

    let endAnimationObject: HTMLElement | null = null;
    if (priceIsTicket) {
      endAnimationObject = (document.getElementsByClassName("ticket-status")[0] as HTMLElement) || null;
    } else if (priceIsCoin) {
      endAnimationObject = (document.getElementsByClassName("token-status")[0] as HTMLElement) || null;
    } else if (priceIsPartnerPoint) {
      endAnimationObject = (document.getElementsByClassName("partner-status")[0] as HTMLElement) || null;
    } else if (priceIsPhysical) {
      endAnimationObject = null;
    } else {
      endAnimationObject = null;
    }

    const startAnimationObject = document?.querySelector(".current-img");
    if (!endAnimationObject || !startAnimationObject) return null;
    const endPositionLeft = endAnimationObject.offsetLeft;
    const endPositionTop = endAnimationObject.offsetTop;
    const startPositionLeft = startAnimationObject.getBoundingClientRect().left;
    const startPositionTop = startAnimationObject.getBoundingClientRect().top;

    const list: JSX.Element[] = [];

    for (let i = 0; i <= 20; i += 1) {
      list.push(
        <div
          key={`animImg${i}`}
          className={`${startAnim && "move"}`}
          css={css`
            position: fixed;
            z-index: ${zIndexAnimationObject};
            visibility: hidden;
            &.move {
              visibility: hidden;
              animation: straightLine ${animationDuration}s ease-in-out;
              animation-delay: ${animationDelayRatio * i}s;
              @keyframes straightLine {
                0% {
                  left: ${startPositionLeft + 25}px;
                  top: ${startPositionTop}px;
                  visibility: visible;
                  transform: translateX(50%) scale(0.8) rotate(0deg);
                  opacity: 1;
                }
                25% {
                  transform: translateX(150%) scale(1.2) rotate(10deg);
                }
                50% {
                  transform: translateX(300%) scale(1) rotate(-10deg);
                }
                75% {
                  transform: translateX(450%) scale(1.1) rotate(10deg);
                  opacity: 1;
                }
                95% {
                  left: ${endPositionLeft}px;
                  top: ${endPositionTop}px;
                  visibility: visible;
                  transform: translateX(500%) scale(0.8) rotate(0deg);
                  opacity: 1;
                }
                100% {
                  opacity: 0;
                  visibility: hidden;
                }
              }
            }
          `}
        >
          <img
            className={`${startAnim && "move-ticket"}`}
            css={css`
              &.move-ticket {
                animation: startTicketAnim ${animationDuration}s ease-in-out;
                animation-delay: ${animationDelayRatio * i}s;
                @keyframes startTicketAnim {
                  0% {
                    transform: rotate(-30deg) scale(0.9);
                  }
                  25% {
                    transform: rotate(30deg) scale(1.2);
                  }
                  50% {
                    transform: rotate(-30deg) scale(1);
                  }
                  75% {
                    transform: rotate(30deg) scale(1.1);
                  }
                  95% {
                    transform: rotate(0deg) scale(0.8);
                    opacity: 1;
                  }
                  100% {
                    opacity: 0;
                  }
                }
              }
            `}
            width="25"
            src={image}
            alt=""
          />
          {/*  <div className="position-relative">
            <div>
              <img
                className={`position-absolute ${startAnim && "move-star"}`}
                css={css`
                  top: ${randomInteger(10, 50)}px;
                  left: ${randomInteger(10, 10)}px;
                  &.move-star {
                    animation: startStarAnim 0.6s ease-in-out infinite;
                    animation-delay: ${0.07 * i}s;
                    @keyframes startStarAnim {
                      0% {
                        opacity: 1;
                        transform: scale(2);
                      }
                      10% {
                        opacity: 0;
                        transform: scale(1.2);
                      }
                      30% {
                        opacity: 1;
                        transform: scale(1);
                      }
                      40% {
                        opacity: 0.1;
                      }
                      60% {
                        opacity: 1;
                      }
                      90% {
                        opacity: 0.5;
                      }
                      100% {
                        opacity: 0.5;
                        transform: scale(1.2);
                      }
                    }
                  }
                `}
                width="50"
                src={Star}
                alt=""
              />
            </div>
          </div> */}
        </div>,
      );
    }
    return list;
  };

  if (!prize || !prize.text || !prize.image) {
    return (
      <Modal.Body>
        <div css={rewardModalStyle}>
          <div className="reward-modal-container">{""}</div>
        </div>
      </Modal.Body>
    );
  }

  const { text, image } = prize;

  return (
    <Modal.Body>
      <div css={rewardModalStyle}>
        <div className="reward-modal-bg">
          <RewardBg />
        </div>
        <div className="reward-modal-container">
          <p className="reward-modal-congrat">
            <Trans i18nKey="congratulations">Congratulations</Trans>
          </p>
          <p className="reward-modal-message">
            <Trans i18nKey="you-have-won">You Have Won</Trans>
          </p>
          <div className="reward-modal-image-container">
            <img src={image} id="prize-img" className="reward-modal-image current-img" alt={text} />
            <span className="reward-modal-text">{text}</span>
          </div>
          {prize?.type === ("INTERNET_PACK" || "CASH") && <div className="prize-disburshed">{message}</div>}

          <button
            onClick={() => {
              setShowWheel(true);
              setPrize(null);
              setShowSpinReward(false);
            }}
            className="reward-modal-button"
          >
            <Trans i18nKey="continue">Continue</Trans>
          </button>
        </div>
      </div>
      {getAnimImg()}
    </Modal.Body>
  );
}

const rewardModalStyle = (theme: ITheme) => css`
  z-index: ${zIndexModal} !important;

  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  text-align: center;
  z-index: 1;
  p {
    margin-bottom: 0;
  }
  .reward-modal-congrat {
    font-weight: 500;
    font-size: 24px;
  }

  .prize-disburshed {
    font-size: 12px;
  }

  .reward-modal-message {
    color: ${theme.button.primaryGradientStart};
    font-family: "Spicy Rice";
    font-size: 28px;
    font-weight: 400;
  }
  .reward-modal-button {
    border: none;
    outline: none;
    background-color: ${theme.button.primaryGradientStart};
    width: 100%;
    height: 43px;
    max-width: 223px;
    border-radius: 12px;
    color: ${theme.button.buttonTextColor};
    font-weight: 600;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .reward-modal-image-container {
    margin-top: 25px;
    img.reward-modal-image {
      display: inline-block;
      aspect-ratio: 1;
      max-height: 60px;
      margin-bottom: 15px;
      &.current-image {
        width: 90px;
      }
    }
    span.reward-modal-text {
      font-size: 45px;
      font-weight: 500;
      padding-right: 15px;
      margin-left: 15px;
    }
  }
  .reward-modal-bg {
    top: -50px;
    position: absolute;
    z-index: -5;
    width: 100%;
    height: 100%;
    svg {
      fill: ${theme.button.tertiaryButtonBackground};
      opacity: 0.5;
    }
  }
  .coin {
    position: absolute;
    width: 30px;
    transition: all 1s ease-out;
    z-index: 999999;
  }
  @keyframes slideCoin {
    0% {
      transform: translate(var(--start-x), var(--start-y));
      opacity: 1;
    }
    100% {
      transform: translate(var(--end-x), var(--end-y));
      opacity: 0;
    }
  }
`;
