import { css } from "@emotion/react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import history from "HistoryWrapper";
import ORNG_ICON from "assets/clients/go3/OrangeTokenIcon.svg";
import GO3_ICON from "assets/clients/go3/Go3_Icon_Light.png";
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import { fetchCommonData, fetchUserTournaments } from "actions";
import {
  go3ABI,
  go3ChainId,
  go3TestNet,
  go3TokenAddress,
  orngABI,
  orngChainId,
  orngMainNet,
  orngTokenAddress,
} from "components/feature-specific/web3/web3constants";
import { formatUnits, BrowserProvider, Contract, ethers } from "ethers";
import React, { useEffect, useLayoutEffect } from "react";
import {
  connectWallet,
  disconnectWallet,
  setBalance,
  setBalances,
  setModalInstance,
} from "redux/reducers/cryptoReducer";
import {
  showPaymentLoading,
  setSelectedTournament,
  onPaymentFailed,
  onPaymentSuccess,
} from "redux/reducers/sdkReducer";
import store, { useAppDispatch, useAppSelector } from "redux/Store";
import api from "actions/api";
import { debounce } from "lodash";
// 1. Your WalletConnect Cloud project ID
const projectId = "d92ac59ab4f1b92d0d6fcbd276310824";

export const handleCryptoPayment = debounce(async (tournament: ITournament) => {
  const { entry_fee_type, entry_fee } = tournament;
  const { name, modalInstance } = store.getState().cryptoToken;
  const isConnected = modalInstance.getIsConnected();
  
  if (!isConnected) {
    modalInstance.open();
    return;
  }

  if (entry_fee_type === "GO3") {
    if (name === "GO3") {
      store.dispatch(setSelectedTournament(tournament));
      try {
        await store.dispatch(cryptoTransferTokens({ amount: entry_fee }));
      } catch (e) {
        // Handle any payment failure
        // store.dispatch(onPaymentFailed());
      }
    } else {
      modalInstance.switchNetwork(go3ChainId);
    }
  } else if (entry_fee_type === "ORNG") {
    if (name === "ORNG") {
      store.dispatch(setSelectedTournament(tournament));
      try {
        await store.dispatch(cryptoTransferTokens({ amount: entry_fee }));
      } catch (e) {
        // Handle any payment failure
        // store.dispatch(onPaymentFailed());
      }
    } else {
      modalInstance.switchNetwork(orngChainId);
    }
  }
}, 300); // Adjust the debounce delay as necessary

// 3. Create a metadata object
const metadata = {
  name: "GGG3",
  description: "GO3",
  // url: "http://192.168.0.135:3000", // origin must match your domain & subdomain
  // url: "https://go-games.gg", // origin must match your domain & subdomain
  url: "https://staging.go-games.gg", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  enableCoinbase: false,
  /*Required*/
  metadata,
  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  // enableCoinbase: true, // true by default
  // rpcUrl: "...", // used for the Coinbase SDK
  // defaultChainId: 1, // used for the Coinbase SDK
});

// eslint-disable-next-line react-refresh/only-export-components
const CryptoInit = () => {
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    // 5. Create a Web3Modal instance
    const modal = createWeb3Modal({
      ethersConfig,
      chains: [go3TestNet, orngMainNet],
      // defaultChain: testnet,
      // tokens: ["GO3", "ORNG"],

      projectId,
      enableAnalytics: true, // Optional - defaults to your Cloud configuration
      allWallets: "HIDE",
      // featuredWalletIds: ["c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96"],
      includeWalletIds: ["c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96"],
      // excludeWalletIds: ["fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa"],
    });
    dispatch(setModalInstance(modal));
    modal.subscribeState(newState => console.log(newState));
    modal.subscribeEvents(event => console.log(event)); // subscribe to events
  }, [dispatch]);
  return null;
};
// eslint-disable-next-line react-refresh/only-export-components
const ConnectButton = ({
  name = "GO3",
  cID = go3ChainId,
  icon = "",
}: {
  name: "GO3" | "ORNG";
  cID: number;
  icon: string;
}) => {
  // const theme: ITheme = useTheme();
  const dispatch = useAppDispatch();
  const { walletProvider } = useWeb3ModalProvider();
  const cryptoToken = useAppSelector(state => state.cryptoToken);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selectedToken, balance, chainId: tChainId, modalInstance, balances } = cryptoToken;
  const { application, country, user } = useAppSelector(state => state.common);
  const accountData = useWeb3ModalAccount();
  const { isConnected, chainId, address } = accountData;

  useEffect(() => {
    if (application && country && isConnected) {
      if (isConnected && address) {
        let tokenAddress = go3TokenAddress;
        let abi = go3ABI;
        if (name === "ORNG") {
          tokenAddress = orngTokenAddress;
          abi = orngABI;
        }
        if (chainId === cID) {
          dispatch(
            initializeWallet({
              walletProvider,
              tokenAddress,
              abi,
              chainId,
              address,
              name,
            }),
          );
        }
      } else {
        dispatch(disconnectWallet());
      }
    }
  }, [address, application, cID, chainId, country, dispatch, isConnected, name, walletProvider]);

  useEffect(() => {
    if (application && country && user && address) {
      if (!user.username.includes(address)) {
        dispatch(
          fetchCommonData({
            app_slug: application?.slug,
            country,
            userid: address,
          }),
        );
      }
    }
  }, [address, application, country, dispatch, user]);

  return (
    <button
      css={css`
        /* text-wrap: nowrap; */
        font-size: 12px;
        border-radius: 10px;
        background: #464343;
        color: #ffffff;
        outline: none;
        border: 1px solid #464343;
      `}
      className="me-1"
      onClick={() => {
        if (isConnected) {
          if (tChainId !== cID) {
            modalInstance.switchNetwork(cID);
          } else {
            modalInstance.open();
          }
        } else {
          modalInstance.open();
        }
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 4px;
          min-width: 75px;
          max-width: 75px;
          padding: 10% 0%;
          overflow: hidden;
          img {
            height: 18px;
            width: auto;
          }
        `}
      >
        <img src={icon} alt="" />
        <b>{!isConnected ? "Connect" : balances[name] || 0}</b>
        {/* {selectedToken === name && isConnected ? (
          <span>
            <b>{balance}</b>
          </span>
        ) : (
          <span>
            <b>{balances[name] ? balances[name] : "Connect"}</b>
          </span>
        )} */}
      </div>
    </button>
  );
};

export const go3Sdk: ISdkResource = {
  type: "built-in",
  goBackHome: null,
  renderInitComponent: () => {
    return <CryptoInit />;
  },
  getTokenStatusBar: () => {
    return <ConnectButton name="ORNG" cID={orngChainId} icon={ORNG_ICON} />;
  },
  getTicketStatusBar: () => {
    return <ConnectButton name="GO3" cID={go3ChainId} icon={GO3_ICON} />;
  },
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  handlePayment: handleCryptoPayment,
};

export const fetchCryptoTokenBalance = createAsyncThunk(
  "cryptoToken/fetchBalance",
  async (_, { dispatch, getState }: any) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { contract, name, address } = getState().cryptoToken;
      // console.log({ contract, address });
      // const balance = await contract.balanceOf(address);
      // const humanReadableBalance = formatUnits(balance, 18);
      // dispatch(setBalance(humanReadableBalance.toString()));

      //  fetch balance of go3
      const balanceGO3 = dispatch(
        setBalances({
          name: "GO3",
          balance: await fetchBalance({
            rpcUrl: "https://subnets.avax.network/orangetest/testnet/rpc",
            address,
            tokenAddress: go3TokenAddress,
            abi: go3ABI,
          }),
        }),
      );
      const balanceORNG = dispatch(
        setBalances({
          name: "ORNG",
          balance: await fetchBalance({
            rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
            address,
            tokenAddress: orngTokenAddress,
            abi: orngABI,
          }),
        }),
      );
      if (name === "GO3") {
        dispatch(setBalance(balanceGO3));
        return balanceGO3;
      }
      if (name === "ORNG") {
        dispatch(setBalance(balanceORNG));
        return balanceORNG;
      }
      return "";
    } catch (e) {
      console.log({ e });
    }
  },
);

export const fetchBalance = async ({ rpcUrl, address, tokenAddress, abi }) => {
  try {
    const xProvider = new ethers.JsonRpcProvider(rpcUrl);
    const contract = new Contract(tokenAddress, abi, xProvider);
    const balance = await contract.balanceOf(address);
    const humanReadableBalance = formatUnits(balance, 18);
    return humanReadableBalance;
  } catch (e) {
    console.log({ e });
    return null;
  }
};

export const initializeWallet = createAsyncThunk(
  "cryptoToken/initializeWallet",
  async ({ walletProvider, tokenAddress, abi, chainId, address, name }: any, { dispatch }) => {
    if (walletProvider) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(tokenAddress, abi, signer);
      dispatch(
        connectWallet({
          address,
          chainId,
          contract,
          abi,
          name,
        }),
      );
      setTimeout(() => {
        dispatch(fetchCryptoTokenBalance());
      }, 300);
      try {
        // const balance = await contract2.balanceOf(address);
        // console.log({ contract2, balance });
      } catch (e) {
        console.error(e);
      }
    }
  },
);
export const cryptoTransferTokens = createAsyncThunk(
  "cryptoToken/transfer",
  async ({ amount = 0 }: any, { getState }: any) => {
    const { application, country } = store.getState().common;
    const { selectedTournament: tournament } = store.getState().sdkReducer;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { contract, address, recipientAddress } = getState().cryptoToken;
    // console.log("Initiated.");
    // console.log(provider);
    // const amount = 10;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const decimals = 18; // Assuming the token has 18 decimals
    const amountInWei = ethers.parseUnits(amount.toString(), "ether");

    try {
      // Check allowance
      // console.log(contract);
      // const allowance = await contract.allowance(address, recipientAddress);
      // console.log(`Allowance: ${allowance.toString()}`);

      // Check balance
      // const balance = await contract.balanceOf(address);
      // console.log(`Balance: ${balance.toString()}`);
      // console.log(`Amount in Wei: ${amountInWei.toString()}`);

      //   if (parseUnits(balance, "ether") > parseUnits(`${amountInWei}`, "ether")) {
      // Approve the token transfer
      const approvalTx = await contract.approve(recipientAddress, amountInWei);
      await approvalTx.wait();

      store.dispatch(showPaymentLoading());

      const initResponse = await api.post(`payments/init/${application?.slug}/`, {
        amount: tournament?.entry_fee,
        tournament: tournament?.id,
      });
      // console.log(`Approved ${amount} tokens to ${recipientAddress}`);
      //   }

      // Transfer the tokens
      const tx = await contract.transfer(recipientAddress, amountInWei, {
        gasLimit: 100000, // Increase gas limit
      });
      console.log(`Transaction hash: ${tx.hash}`);
      await tx.wait();
      const localStorageKey = `active-ref-${tournament?.id}`;
      // console.log(`Transferred ${amount} tokens to ${recipientAddress}`);
      try {
        if (tournament?.id) {
          const initData = initResponse.data;
          const reference = initData.reference;
          localStorage?.setItem(localStorageKey, reference);
          await api.get(`payments/verify/${reference}/?hash=${tx.hash}`);
          localStorage.removeItem(localStorageKey);
          // tournament?.id && (await store.dispatch(registerParticipant(tournament?.id, user?.id)));
          country && (await store.dispatch(fetchUserTournaments(application?.slug, country)));
          setTimeout(() => {
            tournament?.id && history.push(`/tournaments/${tournament.id}/play`);
            store.dispatch(fetchCryptoTokenBalance());
            store.dispatch(onPaymentSuccess());
          }, 2000);
        }
      } catch (e) {
        store.dispatch(onPaymentFailed());
      }
    } catch (error) {
      store.dispatch(onPaymentFailed());
      // console.error("Error transferring tokens", error);
    }
  },
);

export default go3Sdk;
