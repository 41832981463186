import React, { lazy, ReactNode, useContext, useEffect, useRef } from "react";
import { css, useTheme } from "@emotion/react";
import Button from "react-bootstrap/Button";
import { Trans } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { useLocation, useNavigate } from "react-router-dom";
import { useHistory } from "hooks/useHistory";
import { lighten } from "polished";
import cn from "classnames";
import { APP_SLUGS, LAYOUT_TYPES } from "constants/constants";
import { showLoginPopup } from "actions/loginPopupActions";
import { SdkContext } from "providers/client-sdk-providers";
import { WindowSizeContext } from "providers/WindowSizeProvider";
import { isStringEqual } from "utils";
import PartnerBalanceStatus from "routes/partner/components/PartnerBalanceStatus";
import {
  isBurgerKingRu,
  isCroydon,
  isDetikCom,
  isEGEsports,
  isFegg,
  isGcashAliplus,
  isGo3,
  isGoogly,
  isKlook,
  isMetaVerseGo,
  isMok,
  isNequi,
  isOoredoo,
  isOrange,
  isPepsico,
  isTourEast,
  isViva,
  isWinsports,
} from "utils/applicationSlug";
import DesktopNavbar from "components/layouts/desktop/Navbar";
import { hideDesktopView } from "redux/reducers/layoutReducer";
// import RollbarClient from "utils/rollbarClient";

const GiftModal = lazy(() => import("components/feature-specific/Modals/GiftModal"));
const GizerBoostStatus = lazy(() => import("assets/clients/Gizer/GizerBoostStatus"));
// const LoginPopUpHandler = lazy(() => import("Shared/Components/Auth/LoginPopupHandler"));
const PageFooter = lazy(() => import("components/common/PageFooter"));
const TokenStatus = lazy(() => import("routes/tokens/components/TokenStatus"));
const TicketStatus = lazy(() => import("routes/tickets/components/TicketStatus"));
const Navbar = lazy(() => import("components/common/Navbar/Navbar"));
// const CongratsModal = lazy(() => import("Shared/Components/Modals/CongratsModal"));
const Footer = lazy(() => import("components/common/Footer"));
const FullScreenMessage = lazy(() => import("components/feature-specific/pvp/FullScreenMessage"));

interface MobileLayoutProps {
  children: ReactNode;
}

export default function MobileLayout({ children }: MobileLayoutProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const theme: ITheme = useTheme();
  const { pathname } = useLocation();
  const [height, setHeight] = React.useState(window.scrollY);
  const { width } = useContext(WindowSizeContext);
  const sdk = useContext(SdkContext);
  const { goBackHome, getTokenStatusBar = null, getTicketStatusBar = null } = sdk;
  const { layoutType } = useAppSelector((state: any) => state.layout);
  const { error, loading, application, user } = useAppSelector((state: any) => state.common);
  const isDark = isStringEqual(theme?.layout, LAYOUT_TYPES.DARK_V2);
  const navigate = useNavigate();
  const breakpoint = 100;
  const partnerLogoBreakpoint = 1;
  const customPageRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (isMetaVerseGo || isFegg) {
      navigate("/login");
    } else {
      dispatch(showLoginPopup());
    }
  };

  useEffect(() => {
    if (customPageRef.current) {
      customPageRef.current.scrollTop = 0;
    }
  }, [pathname]);

  useEffect(() => {
    const handleScrollListener = () => {
      setHeight(window.scrollY);
    };
    window.addEventListener("scroll", handleScrollListener);
    sdk?.requestBalance && sdk.requestBalance();
    return () => {
      window.removeEventListener("scroll", handleScrollListener);
    };
  }, [sdk]);

  useEffect(() => {
    dispatch(hideDesktopView());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(showMessage());
  //   socket?.off("GAME_STARTED");
  //   socket?.off("GAME_OVER");
  //   dispatch(resetPVP());
  // }, [dispatch, socket]);

  useEffect(() => {
    const handleScroll = () => {
      const fixedNavBarHome = document.getElementsByClassName("navbar-home")[0];
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > 0) {
        fixedNavBarHome?.classList.add("navbar-home-active");
      } else {
        fixedNavBarHome?.classList.remove("navbar-home-active");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let showPartnerLogo = false;

  if (application?.partner_logo) {
    if (width > 1022 && application?.enable_partner_logo_desktop) showPartnerLogo = true;
    else if (width <= 1022 && application?.enable_partner_logo_mobile) showPartnerLogo = true;
  }

  if (user && error && !loading) {
    // RollbarClient.error("404: ", { error, user, notLoading: !loading });
    return <FullScreenMessage>Something Went Wrong!</FullScreenMessage>;
  }

  const getTokenStatus = () => {
    if (getTokenStatusBar) {
      return getTokenStatusBar();
    } else if (application?.slug?.toLowerCase() === APP_SLUGS.GIZER) {
      return <GizerBoostStatus plusButton={false} />;
    } else if (application?.enable_top_bar && height > breakpoint && user && application?.slug === "rappi") {
      return <TokenStatus plusButton={true} />;
    } else if (!application?.enable_top_bar && user && application?.applicationsetting?.enable_coin) {
      return <TokenStatus plusButton={true} />;
    }
  };

  const getTicketStatus = () => {
    if (getTicketStatusBar) {
      return getTicketStatusBar();
    } else if (
      application?.applicationsetting?.enable_user_journey &&
      application?.applicationsetting?.enable_journey_country &&
      !!user
    ) {
      return <TicketStatus plusButton={true} />;
    }
  };

  const getPartnerBalanceStatus = () => {
    // NOTE: Uncomment this when its time to deploy pepsico wip/joy-2 changes
    const showPartnerBalance = application?.applicationsetting?.enable_partner_balance || isPepsico;
    // const showPartnerBalance = application?.applicationsetting?.enable_partner_balance;
    // const showPlusButton = !isPepsico;
    if (showPartnerBalance && !!user) {
      return <PartnerBalanceStatus plusButton />;
    }
  };

  const handleBackButton = () => {
    if (application?.slug?.toLowerCase() === APP_SLUGS.SNICKERS?.toLowerCase()) {
      window.location.href = "https://www.snickers.com.mx/";
    } else if (isMok) {
      window.location.href = user?.extra_data?.urlBack;
    } else if (isTourEast) {
      window.location.href = "https://app-toureast.xctualyfe.com";
    } else if (goBackHome && pathname !== "/tournament/missions") {
      goBackHome();
    } else if (isOoredoo) {
      history.push("/tournament");
    }
  };

  const showBackBtn = () => {
    if (application?.is_back_button_enabled) {
      if (
        !isStringEqual(application?.slug, APP_SLUGS.DANA) &&
        !isGoogly &&
        !isStringEqual(theme?.layout, LAYOUT_TYPES.DARK_V2)
      ) {
        if (user?.extra_data?.urlBack === undefined && isMok) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return isStringEqual(application?.slug, APP_SLUGS.OOREDOO);
    }
  };

  // const DARK = isStringEqual(application?.applicationsetting?.layout_type, LAYOUT_TYPES.DARK_V2) || isPepsico;

  const partnerLogoUrl = application?.partner_logo_link;
  const isGuestUser = application?.applicationsetting?.enable_guest_user && user?.extra_data?.guest;

  const navbarPartnerLogoStyle = css`
    width: auto;
    height: auto;
    max-height: 55px;
    padding-right: 5px;

    &.viva {
      max-height: 50px;
    }
  `;

  const backBtnEnabled = application?.is_back_button_enabled;

  const backButtonColor = isNequi ? theme.background.gradientStop : theme.background.secondary;

  const hidePartnerLogo =
    pathname === "/tournaments/faq" ||
    pathname === "/tournaments/terms-and-conditions" ||
    pathname === "/tournaments/contact-us" ||
    pathname === "/tournaments/privacypolicy";

  if (loading) return null;

  return (
    <div
      css={cardStyle}
      ref={customPageRef}
      className={cn(`custom-page ${application?.slug} ${application?.applicationsetting?.layout_type}_game_page`, {
        "padd-sty sc": pathname === "/tournament" || pathname === "/",
        heroes: pathname === "/tournament/heroes-section",
        "has-partner-logo": showPartnerLogo,
        "has-goama-logo": application?.goama_logo,
        dark: isDark,
      })}
    >
      {pathname !== "/tournament/heroes-section" &&
        pathname !== "/tournament-history" &&
        (pathname.includes("/tournament") || pathname === "/") && (
          <div
            className={cn(`d-flex flex-column col-12 p-0 navbar-home head`, {
              "header-color": layoutType !== LAYOUT_TYPES.DARK_V2,
              "back-button-enabled": showBackBtn(),
              dark: isDark,
            })}
          >
            <div
              className={cn(`container col-12 header-position px-2`, {
                "header-style": layoutType !== LAYOUT_TYPES.DARK_V2,
                "background-sty": layoutType !== LAYOUT_TYPES.DARK_V2,
              })}
            >
              {isWinsports || isCroydon || isEGEsports ? (
                <DesktopNavbar />
              ) : (
                <Navbar backButtonEnabled={showBackBtn()} backButtonColor={backButtonColor} onBack={handleBackButton}>
                  {user && (
                    <div className="group-btn">
                      {getTokenStatus()}
                      {getTicketStatus()}
                      {getPartnerBalanceStatus()}
                    </div>
                  )}
                  {showPartnerLogo && !user && (
                    <img
                      onClick={() => {
                        if (partnerLogoUrl) window.location.href = partnerLogoUrl;
                      }}
                      src={application.partner_logo}
                      alt="partner-logo"
                      css={navbarPartnerLogoStyle}
                      className={cn({ viva: isViva })}
                    />
                  )}
                  {isGuestUser && !isDetikCom && (
                    <Button
                      className={cn("login-active me-2", { "back-button-disable": backBtnEnabled })}
                      onClick={handleClick}
                    >
                      <Trans i18nKey="login">Login</Trans>
                    </Button>
                  )}
                  {!user && (
                    <Button
                      className={cn("login-active", { "back-button-disable": backBtnEnabled })}
                      onClick={handleClick}
                    >
                      <Trans i18nKey="login">Login</Trans>
                    </Button>
                  )}
                </Navbar>
              )}
            </div>

            {user && (showPartnerLogo || application?.goama_logo) && !isKlook && (
              <div
                className={cn("d-flex justify-content-center pb-1 navbar-logos", {
                  "pb-2": application?.slug !== "viva",
                })}
              >
                {isBurgerKingRu && showPartnerLogo && (
                  <img
                    onClick={() => {
                      if (application?.partner_logo_link) window.location.href = application.partner_logo_link;
                    }}
                    src={application.partner_logo}
                    alt="partner-logo"
                    className="king-burger-navbar-logo"
                  />
                )}

                {!isBurgerKingRu &&
                  !isOrange &&
                  !isGo3 &&
                  showPartnerLogo &&
                  partnerLogoBreakpoint > height &&
                  !hidePartnerLogo && (
                    <img
                      onClick={() => {
                        if (application?.partner_logo_link) window.location.href = application.partner_logo_link;
                      }}
                      src={application.partner_logo}
                      alt="partner-logo test"
                      css={
                        application?.slug !== "viva"
                          ? css`
                              width: auto;
                              max-height: 50px;
                              padding-right: 5px;
                            `
                          : css`
                              width: auto;
                              height: auto;
                              max-height: 55px;
                              padding-right: 5px;
                            `
                      }
                    />
                  )}

                {application?.goama_logo && (
                  <img
                    src={application.goama_logo}
                    alt="goama"
                    css={css`
                      width: auto;
                      height: 50px;
                      padding-left: 10px;
                    `}
                  />
                )}

                {application?.partner_logo && isOrange && (
                  <img
                    src={application.partner_logo}
                    alt="orange"
                    css={css`
                      width: auto;
                      height: 35px;
                      padding-left: 10px;
                      position: absolute;
                      top: 15px;
                      left: 0; /* Align to the left */
                      text-align: left; /* Align text to the left */
                    `}
                  />
                )}

                {application?.partner_logo && isGo3 && (
                  <img
                    src={application.partner_logo}
                    alt="orange"
                    css={css`
                      width: auto;
                      height: 25px;
                      padding-left: 10px;
                      position: absolute;
                      top: 20px;
                      left: 0; /* Align to the left */
                      text-align: left; /* Align text to the left */
                    `}
                  />
                )}
              </div>
            )}
          </div>
        )}

      {children}
      {/* <Routes>
          <Route path="/" element={<TournamentGamesPage showTopTournaments />} />
          <Route path="/my-games" element={<MyGamesPage />} />
          <Route path="/heroes-section" element={<HeroesSectionPage />} />
          <Route path="/play-featured-game" element={<FeaturedGameDirectPlayPage />} />
          <Route path="/play-featured-game/:serial" element={<FeaturedGameDirectPlayPage />} />
          <Route path="/missions" element={<MissionsPage />} />
        </Routes> */}
      {location.pathname === "/tournament" && <GiftModal />}
      {history.location.pathname !== "/C2PplayGamePage" && history.location.pathname !== "/coin-purchase" && (
        <div
          className={
            (history.location.pathname === "/token-profile" || history.location.pathname === "/redeem") &&
            isGcashAliplus
              ? "gcashaliplus-footer-wrapper"
              : "footer-wrapper"
          }
        >
          <div css={{ paddingBottom: "100px" }} className="mobile-footer-page">
            <PageFooter />
          </div>
          <div className="col-12 footer-sticky-homepage">{/* <Advertisement name="Sticky Footer Home Page" /> */}</div>
          <Footer />
        </div>
      )}
    </div>
  );
}

const cardStyle = (theme: ITheme) => css`
  /* background-color: ${theme.background.primary}; */
  height: 100vh;
  overflow-y: scroll;
  &.padd-sty {
    padding-top: 5rem;
    &.has-goama-logo {
      padding-top: 8rem;
    }
    &.has-partner-logo {
      padding-top: 8rem;
    }
    &.SINGLE_FEATURED_game_page {
      height: 100% !important;
      padding-top: 0;
      .header-color,
      .background-sty {
        background-color: transparent;
      }
      .tournament-games-page {
        min-height: 100%;
      }
    }
    &.dark {
      padding-top: 0;
    }
  }
  &.dark {
    padding-top: 0;
  }
  color: ${theme.text.primary};
  width: 100vw;
  .navbar-home {
    &.dark {
      &.navbar-home-active {
        background: #ffffff6b;
      }
    }
  }
  &.globe {
    .heroes {
      left: 35%;
    }
    .winner-label {
      padding-top: 40px;
    }
  }
  &.heroes {
    /* padding-bottom: 20px; */ // TODO: COMMENT
  }

  &.sc {
    -webkit-overflow-scrolling: touch !important;
  }

  .footer-sticky-homepage {
    z-index: 999;
    position: fixed;
    left: 0;
    bottom: 4rem;
    width: 100vw;
    /* margin-bottom: 12px; */
  }

  .header-sticky-homepage {
    position: absolute;
    /* z-index: 999;
    position: fixed;
    left: 0;
    top: 45px;
    width: 100vw;
    margin-top: 15px; */
  }

  .head {
    position: fixed;
    top: 0;
    z-index: 33;
  }

  .header-color {
    background-color: ${theme.background.primary};
  }

  .header-style {
    background-color: ${theme.background.primary};
    z-index: 9;
    /* position: fixed; */
  }

  .header-position {
    z-index: 999;
    top: 0;
    left: 50%;
    /* transform: translateX(-50%); */
    /* background-color: ${theme.background.primary}; */
  }

  .background-sty {
    background-color: ${theme.background.primary};
  }

  .heroes {
    position: absolute;
    z-index: 99;
  }

  .header-section {
    color: ${theme.foreground.primary};
    font-weight: 600;
    font-size: 20px;

    @media (min-width: 1000px) {
      font-size: 30px;
    }
  }

  /* .backbutton-off {
    margin-left: 33px;
  } */

  .term-style {
    font-size: 12px;
  }

  .background {
    background-color: ${theme.background.secondary};
  }

  .info-style {
    width: 14px;
    height: 14px;
    fill: #007bff;
  }

  .login-active {
    font-weight: 600 !important;
    border-radius: 25px;
    color: ${lighten(1, theme.text.primary)};
    background: linear-gradient(
      270deg,
      ${theme.button.primaryGradientStart} 0%,
      ${theme.button.primaryGradientStop} 122.67%
    ) !important;
    text-decoration: none !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
      color: ${lighten(1, theme.text.primary)} !important;
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      ) !important;
      border-color: ${theme.button.primaryGradientStart} !important;
    }
  }
  .back-button-disable {
    margin-left: auto;
  }
  .group-btn {
    display: flex;
  }

  .gcashaliplus-footer-wrapper {
    background: #ffffff !important;
    position: relative;
    z-index: 9999;
  }
`;
function useQuery() {
  throw new Error("Function not implemented.");
}
