import React, { useEffect } from "react";
import { fetchAdvertisements, fetchCampaign } from "actions";
// import tournamentsAPI from "actions/api/tournaments";
import i18n from "I18n";
import {
  fetchLoginPopCounter,
  hideLeaderboardPopup,
  hidePopUpAll,
  showThemeLayout,
} from "redux/reducers/layoutReducer";
import { setUserTeam } from "redux/reducers/teamReducer";
import { fetchTranslations } from "redux/reducers/uiReducer";
import { SdkContext } from "providers/client-sdk-providers";
import { SocketContext } from "providers/SocketProvider";
import { type RootState, useAppDispatch, useAppSelector } from "redux/Store";
import { decodeDataFromURL } from "utils";
import { isKlook, isWowGames } from "utils/applicationSlug";
import RollbarClient from "utils/rollbarClient";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import api from "actions/api";
import { toggleGameListPopup } from "redux/reducers/modalReducer";
// import { useHistory } from "hooks/useHistory";

// import { AppState } from "./types"; // Assume you have a type definition for AppState

export const useMain = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  // const history = useHistory();
  const { sessionSocket, initiateSessionSocket } = React.useContext(SocketContext);
  const sdk = React.useContext(SdkContext);
  const { translations, application, user, popUp, gamelistpopup } = useSelectors();
  const isDesktop = useMediaQuery({ query: "(min-width: 1022px)" });

  /* useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handlePopState = (_event: PopStateEvent) => {
      handlePopStateEvent(dispatch, history);
    };
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */
  // Handlers for various effects
  useEffect(
    () => handleSocketConnections(user, sessionSocket, initiateSessionSocket),
    [user, sessionSocket, initiateSessionSocket],
  );
  useEffect(() => handlePhoneNumberRetrieval(user, application, sdk), [sdk, user, application]);
  useEffect(
    () => fetchUserTeamData(user, application, dispatch, sdk),
    [application, application?.applicationsetting?.team_base_application, dispatch, sdk, user],
  );
  useEffect(() => getTranslations(dispatch), [dispatch]);
  useEffect(() => setTranslations(translations), [translations]);
  useEffect(() => handleAdvertisementFetching(dispatch), [dispatch]);
  useEffect(() => logURLParams(), []);
  useEffect(() => handleIOS(), []);
  useEffect(() => fetchEthereumData(application), [application]);
  useEffect(
    () => handleGuestUserLoginPopup(application?.applicationsetting?.enable_guest_user, dispatch, application),
    [application, dispatch],
  );
  useEffect(() => handleSocialMediaAuth(user), [user]);
  useEffect(() => adjustUILayout(application, dispatch), [application, dispatch, isDesktop]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleLeaderboardPopup(popUp, gamelistpopup, dispatch), [dispatch, isDesktop]);
  useEffect(() => handleLeaderboardPopupLocation(location, dispatch), [dispatch, location, location.pathname]);
  useEffect(() => {
    const { handleTouchStart, handleTouchMove } = preventHorizontalScrollOnTouch();
    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, [application?.slug]);

  return null;
};

// Redefine useSelectors with explicit return type
function useSelectors() {
  return {
    translations: useAppSelector((state: RootState) => state.ui.translations),
    application: useAppSelector((state: RootState) => state.common.application),
    user: useAppSelector((state: RootState) => state.common.user),
    popUp: useAppSelector((state: RootState) => state.layout.popUp),
    gamelistpopup: useAppSelector((state: RootState) => state.modal.gamelistpopup),
  };
}

// Define handlers as functions with appropriate parameters and types
function handleSocketConnections(user: any, sessionSocket: any, initiateSessionSocket: Function) {
  if (sessionSocket === null) {
    initiateSessionSocket();
  }
  if (user !== null) {
    sessionSocket?.emit("login", { userId: user?.id });
  }
}

function handlePhoneNumberRetrieval(user: any, application: any, sdk: any) {
  if (user && application) {
    if (!user.msisdn) {
      if (sdk.getPhoneNumber) {
        sdk.getPhoneNumber().then((value: any) => {
          if (value) {
            api
              .post(`tournaments/users/msisdn/${application?.slug}/`, {
                msisdn: value.phone,
                sign: value.sign,
              })
              .then();
          }
        });
      }
    }
  }
}

function fetchUserTeamData(user: any, application: any, dispatch: any, sdk: any) {
  if (application) {
    if (user) {
      if (application?.applicationsetting?.team_base_application) {
        api
          .get(`tournaments/team-based-application/teams/${application?.slug}/check/`)
          .then(response => {
            if (response.data) {
              if (response?.data?.memmber_of) {
                dispatch(setUserTeam(response?.data?.memmber_of) as any);
              } else {
                dispatch(setUserTeam(null) as any);
              }
            }
          })
          .catch(() => {
            dispatch(setUserTeam(null) as any);
          });
      }
      setTimeout(() => {
        dispatch(fetchCampaign() as any);
      }, 2000);
      // setShowSpinningWheel(true);
      if (sdk.postInitCallback) sdk.postInitCallback(user);
    }
  }
}

function getTranslations(dispatch: any) {
  const { country, app_slug } = decodeDataFromURL();
  dispatch(fetchTranslations(`${app_slug}`, `${country}`) as any);
}

function setTranslations(translations: any) {
  let { country } = decodeDataFromURL();
  country = country?.toUpperCase() || "";
  if (translations) {
    const resources = translations;
    i18n.addResourceBundle("en", "translation", resources?.en?.translation, true, true);
    i18n.addResourceBundle(country, "translation", resources?.[country]?.translation, true, true);
    i18n.changeLanguage(country);
  }
}

function handleAdvertisementFetching(dispatch: any) {
  const { app_slug, country, showAds } = decodeDataFromURL();
  if (showAds) dispatch(fetchAdvertisements(`${app_slug}`, `${country}`) as any);
}

function logURLParams() {
  const params = decodeDataFromURL(window.location.href);
  if (isWowGames) {
    RollbarClient?.error(`${params?.app_slug} URL PARAMS`, window.location.href, {
      url: window.location.href,
      params,
    });
  }
}

function fetchEthereumData(application: any) {
  const baseURL = "https://c2c.go48.co/api/eth";
  if (application?.slug === "goamalabs") {
    axios
      .get(baseURL)
      .then((response: any) => localStorage?.setItem(`ethereum`, response.data.eth))
      .catch(e => console.error(e));
  }
}

function handleGuestUserLoginPopup(isGuestUser: boolean, dispatch: any, application: any) {
  if (isGuestUser && application) {
    dispatch(fetchLoginPopCounter() as any);
  }
}

function handleSocialMediaAuth(user: any) {
  if (!user) return;
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const userId = params.get("state");
  const socialAuth = params.get("social_login");

  if (socialAuth !== "apple") {
    localStorage.removeItem("apple-auth");
    sessionStorage.removeItem("connected-apple");
  } else {
    if (userId === localStorage.getItem("apple-auth")) {
      const connectedBackened = sessionStorage.getItem("connected-apple");
      if (connectedBackened) {
        sessionStorage.setItem("connected-apple", "apple");
      } else {
        sessionStorage.setItem("connected-apple", "apple");
      }
      localStorage.removeItem("apple-auth");
    }
  }
}

function adjustUILayout(application: any, dispatch: any) {
  dispatch(showThemeLayout({ layoutType: application?.ui_customization?.layout_type }));
}

function handleLeaderboardPopup(popUp: any, gamelistpopup: any, dispatch: any) {
  if (gamelistpopup) {
    dispatch(toggleGameListPopup());
  }

  if (popUp) {
    dispatch(hidePopUpAll());
  }
}

function handleLeaderboardPopupLocation(location: any, dispatch: any) {
  if (location.pathname !== "/tournament") {
    // history.replace({ search: "" });
    dispatch(hideLeaderboardPopup());
  }
}

function handleIOS() {
  // iOS context-menu workaround
  window.getSelection()?.removeAllRanges();
}

function preventHorizontalScrollOnTouch() {
  let startX = 0;
  let startY = 0;

  const handleTouchStart = (event: TouchEvent) => {
    startX = event.touches[0].clientX;
    startY = event.touches[0].clientY;
  };

  const handleTouchMove = (event: TouchEvent) => {
    const deltaX = event.touches[0].clientX - startX;
    const deltaY = event.touches[0].clientY - startY;

    // Check if horizontal swipe is more significant than vertical swipe
    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      if (isKlook) {
        event.preventDefault(); // Prevent default scroll behavior horizontallyKLOOK)) {
        event.stopPropagation(); // Prevent default scroll behavior horizontallyKLOOK)) {
      }
    }
  };

  document.addEventListener("touchstart", handleTouchStart, { passive: true });
  document.addEventListener("touchmove", handleTouchMove, { passive: false });
  return { handleTouchStart, handleTouchMove };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* const handlePopStateEvent = (dispatch: AppDispatch, history) => {
  const params = new URLSearchParams(window.location.search);
  const tournamentId = params.get("tournament");
  const open_prize = params.get("open_prize");
  // console.log({ tournamentId, open_prize });

  if (tournamentId) {
    // Prevent showing the popup again
    params.delete("tournament");
    if (open_prize) params.delete("open_prize");
    dispatch(hideLeaderboardPopup());
    window.history.replaceState(null, "", `${window.location.pathname}?${params.toString()}`);
  } else {
    // Handle exiting the webview or other logic
  }
};
 */
