import { createSlice } from "@reduxjs/toolkit";

// import statusAPI from "actions/api/status";
// import balanceAPI from "actions/api/balance";
import { decodeDataFromURL } from "utils";
import api from "actions/api";
import { AppDispatch } from "redux/Store";
// import tournamentsAPI from "actions/api/tournaments";

export interface ITicketInfo {
  image: string;
  subtitle: string;
  title: string;
}

export interface IStatus {
  balance: any[];
  data: any[];
  current_user: any;
  alltime_data: any;
  monthly_data: any;
  weekly_data: any;
  daily_data: any;
  user_platform_duration: any;
  ticketInfos: ITicketInfo[];
  ticketInfosState: "idle" | "loading" | "error";
  loading: boolean;
  error: boolean;
}

const initialState: IStatus = {
  balance: [],
  data: [],
  current_user: [],
  alltime_data: [],
  monthly_data: [],
  weekly_data: [],
  daily_data: [],
  user_platform_duration: [],
  ticketInfos: [],
  ticketInfosState: "idle",
  loading: false,
  error: false,
};

const statusSlice = createSlice({
  name: "statusPoints",
  initialState,
  reducers: {
    fetchStatusPointsLoading(state) {
      state.loading = true;
    },
    fetchStatusPointsSuccess(state, action) {
      state.balance = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchStatusPointsError(state) {
      state.balance = [];
      state.loading = false;
      state.error = true;
    },

    fetchBalanceLoading(state) {
      state.loading = true;
    },
    fetchBalanceSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchBalanceError(state) {
      state.data = [];
      state.loading = false;
      state.error = true;
    },

    fetchCurrentUserDetailLoading(state) {
      state.loading = true;
    },
    fetchCurrentUserDetailSuccess(state, action) {
      state.current_user = action.payload;
      state.loading = false;
      state.error = false;
    },
    fetchCurrentUserDetailError(state) {
      state.current_user = [];
      state.loading = false;
      state.error = true;
    },

    fetchAllTimeDataLoading(state) {
      state.loading = true;
    },
    fetchAllTimeDataSuccess(state, action) {
      state.alltime_data = action.payload?.all_time_tickets;
      state.loading = false;
      state.error = false;
    },
    fetchAllTimeDataError(state) {
      state.alltime_data = [];
      state.loading = false;
      state.error = true;
    },

    fetchMonthlyDataLoading(state) {
      state.loading = true;
    },
    fetchMonthlyDataSuccess(state, action) {
      state.monthly_data = action.payload?.monthly_tickets;
      state.loading = false;
      state.error = false;
    },
    fetchMonthlyDataError(state) {
      state.monthly_data = [];
      state.loading = false;
      state.error = true;
    },

    fetchWeeklyDataLoading(state) {
      state.loading = true;
    },
    fetchWeeklyDataSuccess(state, action) {
      state.weekly_data = action.payload?.weekly_tickets;
      state.loading = false;
      state.error = false;
    },
    fetchWeeklyDataError(state) {
      state.weekly_data = [];
      state.loading = false;
      state.error = true;
    },

    fetchDailyDataLoading(state) {
      state.loading = true;
    },
    fetchDailyDataSuccess(state, action) {
      state.daily_data = action.payload?.daily_tickets;
      state.loading = false;
      state.error = false;
    },
    fetchDailyDataError(state) {
      state.daily_data = [];
      state.loading = false;
      state.error = true;
    },

    fetchUserPlatformDurationSuccess(state, action) {
      state.user_platform_duration = action.payload;
      state.loading = false;
      state.error = false;
    },

    clearUserStatusPoints(state) {
      state.data = [];
      state.loading = false;
      state.error = false;
    },
    changeTicketInfosState(state, action: { type: string; payload: "idle" | "loading" | "error" }) {
      state.ticketInfosState = action.payload;
    },
    fetchTicketInfosSuccess(state, action) {
      state.ticketInfosState = "idle";
      state.ticketInfos = action.payload;
    },
  },
});

export const {
  fetchStatusPointsError,
  fetchStatusPointsSuccess,
  fetchStatusPointsLoading,

  fetchBalanceLoading,
  fetchBalanceSuccess,
  fetchBalanceError,

  fetchCurrentUserDetailLoading,
  fetchCurrentUserDetailSuccess,
  fetchCurrentUserDetailError,

  fetchAllTimeDataLoading,
  fetchAllTimeDataSuccess,
  fetchAllTimeDataError,

  fetchMonthlyDataLoading,
  fetchMonthlyDataSuccess,
  fetchMonthlyDataError,

  fetchWeeklyDataLoading,
  fetchWeeklyDataSuccess,
  fetchWeeklyDataError,

  fetchDailyDataLoading,
  fetchDailyDataSuccess,
  fetchDailyDataError,

  fetchUserPlatformDurationSuccess,

  clearUserStatusPoints,
  changeTicketInfosState,
  fetchTicketInfosSuccess,
} = statusSlice.actions;

export default statusSlice.reducer;

export const fetchStatusPoints = () => async (dispatch: AppDispatch) => {
  dispatch(fetchStatusPointsLoading());
  const { country } = decodeDataFromURL();
  return api
    .get(`balance/ticket/${country}/?timestamp=${Date.now()}`)
    .then(response => {
      dispatch(fetchStatusPointsSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchStatusPointsError()));
};

export const fetchBalance = () => async (dispatch: AppDispatch) => {
  dispatch(fetchBalanceLoading());
  const { country } = decodeDataFromURL();
  return api
    .get(`statuspoints/user-journey-status/`, { params: { country } })
    .then(response => {
      dispatch(fetchBalanceSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchBalanceError()));
};

export const fetchCurrentUserDetails = (country: any, type?: any) => async (dispatch: AppDispatch) => {
  dispatch(fetchCurrentUserDetailLoading());
  return api
    .get(`statuspoints/ticket/user/record/${country}/`, { params: { type } })
    .then(response => {
      dispatch(fetchCurrentUserDetailSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchCurrentUserDetailError()));
};

export const fetchAllTimeData = (country: any, application: any) => async (dispatch: AppDispatch) => {
  dispatch(fetchAllTimeDataLoading());
  return api
    .get(`statuspoints/ticket/alltime/record/${country}/${application}/`)
    .then(response => {
      dispatch(fetchAllTimeDataSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchAllTimeDataError()));
};

export const fetchMonthlyData = (country: any, application: any) => async (dispatch: AppDispatch) => {
  dispatch(fetchMonthlyDataLoading());
  return api
    .get(`statuspoints/ticket/monthly/record/${country}/${application}/`)
    .then(response => {
      dispatch(fetchMonthlyDataSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchMonthlyDataError()));
};

export const fetchWeeklyData = (country: any, application: any) => async (dispatch: AppDispatch) => {
  dispatch(fetchWeeklyDataLoading());
  return api
    .get(`statuspoints/ticket/weekly/record/${country}/${application}/`)
    .then(response => {
      dispatch(fetchWeeklyDataSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchWeeklyDataError()));
};

export const fetchDailyData = (country: any, application: any) => async (dispatch: AppDispatch) => {
  dispatch(fetchDailyDataLoading());
  return api
    .get(`statuspoints/ticket/daily/record/${country}/${application}/`)
    .then(response => {
      dispatch(fetchDailyDataSuccess(response.data));
      return response.data;
    })
    .catch(() => dispatch(fetchDailyDataError()));
};

export const fetchTicketInfos = (application: IApplication) => (dispatch: AppDispatch) => {
  dispatch(changeTicketInfosState("loading"));
  return api
    .get(`tournaments/ticketinfo/${application?.slug}/`)
    .then(({ data }) => dispatch(fetchTicketInfosSuccess(data)))
    .catch(() => {
      dispatch(changeTicketInfosState("error"));
    });
};

export const fetchUserPlatformDuration = () => (dispatch: AppDispatch) => {
  dispatch(fetchStatusPointsLoading());
  return (
    api
      .get(`statuspoints/user/left/platform/`)
      .then(({ data }) => dispatch(fetchUserPlatformDurationSuccess(data)))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch(e => {
        // console.log(e);
      })
  );
};
