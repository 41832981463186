import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";
import { AppDispatch } from "redux/Store";
// import tournamentsAPI from "actions/api/tournaments";

export interface ITournamentPrizesState {
  error: boolean;
  loading: boolean;
  data: any;
}

const initialState: ITournamentPrizesState = {
  error: false,
  loading: false,
  data: {}
};

const tournamentPrizesSlice = createSlice({
  name: "tournamentPrizes",
  initialState,
  reducers: {
    fetchTournamentPrizesLoading(state) {
      state.loading = true;
    },
    fetchTournamentPrizesSuccess(state, action) {
      state.data = {
        ...state.data,
        [action.payload.tournamentId]: action.payload.data
      };
      state.loading = false;
      state.error = false;
    },
    fetchTournamentPrizesError(state) {
      state.data = {};
      state.loading = false;
      state.error = true;
    }
  }
});

export const {
  fetchTournamentPrizesLoading,
  fetchTournamentPrizesSuccess,
  fetchTournamentPrizesError
} = tournamentPrizesSlice.actions;

export default tournamentPrizesSlice.reducer;

export const fetchTournamentPrizes = (tournamentId: number) => async (
  dispatch: AppDispatch
) => {
  dispatch(fetchTournamentPrizesLoading());
  return api
    .get(`tournaments/${tournamentId}/prizes/`)
    .then(response => {
      dispatch(
        fetchTournamentPrizesSuccess({ tournamentId, data: response.data })
      );
      return response.data;
    })
    .catch(() => dispatch(fetchTournamentPrizesError()));
};
