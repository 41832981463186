import { css } from "@emotion/react";
import { APP_SLUGS } from "constants/constants";

export const Go3 = css`
  &.${APP_SLUGS.GO3} {
    .top-tournament-full-width {
      .game-details {
        color: #fa6200;
      }
      .swiper-pagination-bullet-active {
        background: #fa6200;
      }
      .text-play,
      .rank-btn {
        box-shadow: 0 0 5px 3px #fa6200;
        background-color: #fa6200 !important;
        color: #ffffff !important;
      }
    }
    .leaderboard-popup-item {
      .dummy-rank-list {
        .row {
          padding: 5%;
          .col-items {
            color: #d9d9d9 !important;
            > div {
              background: #d9d9d9 !important;
            }
          }
        }
        .unlock-style {
          background: #fa6200 !important;
          box-shadow: 0 0 5px 3px #fa6200 !important;
        }
      }
      .img-style {
        border: 2px solid #fa6200;
        height: 80px;
      }
      > * {
        color: #d9d9d9 !important;
      }
      .close_wrapper {
        svg {
          fill: #fa6200 !important;
        }
      }
      background: #000000;
      .modal_header {
        background: #000000;
      }
      .drag-handle:after {
        background-color: #fa6200;
      }
      .prizes-slider-item {
        background-color: #202020 !important;
        .icon {
          background: #000000 !important;
          svg {
            fill: #d9d9d9;
          }
        }
      }
      .rank-list-card {
        .label-no-ranks {
          color: #fa6200 !important;
        }
        > *,
        .text-style-transaction {
          color: #d9d9d9 !important;
        }
      }
      .prizes-slider-container {
        border: 0 !important;
      }
      .rank-list-container {
        box-shadow: none !important;
      }
      .rank-list-container,
      .rank-list-container-first {
        .list-group-item {
          background-color: #202020;
        }
      }
      .prize-list-container {
        background-color: #202020 !important;
        .prize-calculator-item {
          .label-prize {
            b {
              display: flex;
              justify-content: center;
              flex-direction: row;
              align-items: center;
            }
          }
        }
        .label-rank {
          color: #d9d9d9 !important;
        }
        .label-prize {
          color: #d9d9d9 !important;
        }
      }
    }
    .tournament-list-popup {
      .small-card .border-style {
        border-bottom: 0.1px solid #000000;
      }
      .img-style {
        border: 1px solid #fa6200 !important;
      }
      .title-md {
        color: #d9d9d9;
      }
      .gamename-style,
      .title-header {
        color: #fa6200;
      }
      .close_wrapper {
        svg {
          fill: #fa6200 !important;
        }
      }
      .small-card {
        background-color: #2b2b2b !important;
        .name-sty {
          color: #d9d9d9 !important;
          > * {
            color: #d9d9d9 !important;
          }
          svg {
            fill: #d9d9d9 !important;
          }
        }
        .rank-btn {
          background: #424242 !important;
          .rank-icon {
            fill: #fa6200;
          }
        }
        .play-btn-browse {
          background: #000000 !important;
          color: #fa6200;
          .play-icon {
            fill: #fa6200;
          }
        }
      }
    }
    .profile-page {
      .top-bar-wrapper {
        .token-sty {
          gap: 2px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
        }
      }
      .back-button-wrapper {
        svg {
          fill: #fa6200 !important;
          stroke: #fa6200 !important;
        }
      }
      .user-profile-customization {
        .support-style {
          background: #fa6200 !important;
          svg {
            fill: #000000 !important;
          }
        }
      }
      .profile-page-body {
        .tournament-history-btn,
        .show-all-text {
          color: #fa6200 !important;
        }
        .profile-history-list {
          .profile-history-wrapper {
            background: #202020;
          }
          .history-border {
            color: #fa6200;
          }
          .rank-list-avatar {
            border: 2px solid #fa6200;
          }
        }
      }
    }
    .tournament-info-popup {
      .drag-handle::after {
        background: #fa6200 !important;
      }
      .close_wrapper {
        > * {
          fill: #fa6200 !important;
        }
      }
      .tournament-info-list {
        .header-line {
          color: #fa6200 !important;
        }
        .white-card-design {
          background-color: #202020 !important;
          .rank-style {
            color: #fa6200 !important;
          }
        }
      }

      .leaderboard-body {
        .header-line {
          color: #fa6200 !important;
        }
        .avatar-row {
          .competitor-name {
            color: #fa6200 !important;
          }
          .avatar1,
          .avatar {
            border: 1px solid #fa6200;
          }
        }
      }
      .rank-list-container,
      .rank-list-container-first {
        background: #202020 !important;
      }

      .achievement-history-list-item {
        background: #202020 !important;
        .text-style-transaction,
        .text-style-transaction,
        .body-xs {
          color: #fa6200 !important;
        }
      }
    }
    .tournament-history-page {
      .back-button-wrapper {
        svg {
          fill: #fa6200 !important;
          stroke: #fa6200 !important;
        }
      }
      .header-style {
        color: #fa6200 !important;
      }
      .white-card {
        background-color: #202020;
        > * {
          color: #fa6200 !important;
        }
      }
      .street-text {
        color: #fa6200;
      }
      .txt-style,
      .won-style {
        color: #ffffff;
      }
      .second-row {
        .label {
          color: #ffffff;
        }
        .label-value {
          color: #fa6200;
        }
      }
    }
    .custom-popup-width {
      .close_wrapper {
        svg {
          fill: #ffffff !important;
        }
      }
    }
    .GO3-modal-content {
      background: #202020 !important;
      .modal-title {
        color: #fa6200 !important;
      }
      .btn-close {
        /* background: #fa6200 !important; */
      }
    }
    .browse-game-list {
      .browse-games {
        padding: 0;
      }
      .browse-games-rectangular-card {
        background-color: rgb(77, 77, 77) !important;
        /* box-shadow: rgb(40 10 3) -9px -20px 42px 9px inset !important; */
        border: 1px solid #fa62007d !important;
        img {
          border: 1px solid #fa6200 !important;
        }
      }
    }
    .login-active {
      display: none;
    }
    .tournament-list-item {
      .play-btn-browse {
        > div {
          gap: 2px !important;
        }
      }
    }
    .browse-games-rectangular-card {
      .participant-counter-row {
        .count {
          color: #fa6200 !important;
        }
      }
    }
    .heroes-time-range {
      .not-active-button {
        color: #ffffff;
      }
    }
    .avatar-update-modal {
      .modal-title {
        color: #000000;
      }
    }
    .footer-wrapper {
      .mobile-footer-page {
        margin-top: 30px;
        padding-bottom: 0;
      }
    }
    .browse-popup {
      .close_wrapper {
        svg {
          fill: #ffffff;
        }
      }
    }
    .desktop-play-footer {
      box-shadow: 0 0 5px 3px #fa6200;
      background: #ffa500 !important;
      img {
        width: 20px !important;
        max-width: 20px !important;
      }
    }

    .button-orange {
      button {
        color: #ffffff !important;
      }
    }

    .heroes {
      .first-part {
        background: #fa6200 !important;
      }
    }

    .rank-list-container {
      h5 {
        /* color: #ffffff !important; */
      }
    }

    .header-container {
      .title {
        color: #ffffff !important;
      }
    }

    .activated-button {
      color: #fa6200 !important;
    }

    .top-bar-wrapper {
      .fontHeadStyle,
      .fontSubStyle {
        color: #fa6200 !important;
      }
    }

    .page-wrapper {
      .top-bar {
        .top-bar-top {
          background: #000000 !important;
          box-shadow: none !important;
        }
      }
    }

    .custom-popup-container {
      .coming-soon-text,
      span {
        color: #fa6200 !important;
      }
    }

    .footer-content {
      display: none !important;
      background-color: #000000 !important;
      a:not(.active-link) {
        > * {
          color: #fff;
          fill: #fff;
        }
      }
    }

    .headerInfoWrapper {
      .no-info {
        color: #fa6200 !important;
      }
    }

    .mission-page-container {
      .section-header {
        color: #fa6200 !important;
      }
    }

    .single-game-card {
      .square-card {
        background-color: #e5e5e5 !important;
        .card-title,
        .timer-data {
          color: #000000 !important;
        }
      }
    }

    .progressbar_wrapper {
      .small_circle {
        border: 7px solid #e5e5e5 !important;
      }

      .RCP__track {
        stroke: #e5e5e5 !important;
      }
    }

    .progress_bottom_curve {
      background-color: #ffffff !important;
      .timer {
        .clock_icon {
          padding-bottom: 5px !important;
        }
      }
    }

    .helper_text {
      color: #000000 !important;
    }

    .active_progress_bottom_curve {
      background-color: #fa6200 !important;
    }

    .action_button {
      button {
        color: #000000 !important;
      }
    }

    .homepage-background {
      box-shadow: rgb(202 166 143) -1pt 5pt 5px 0px;
    }
  }
  .recommended-games {
    background-color: #000000 !important;
  }
  .half-style {
    background: linear-gradient(to bottom, rgba(176, 71, 0, 1), rgba(241, 109, 0, 1)) !important;
  }
`;

export default Go3;
