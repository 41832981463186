import React from "react";
import { css } from "@emotion/react";

const cloudStyle = css`
  z-index: 999;
  height: 100%;
  html,
  body {
    height: 100%;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    background: #f3f4f4;
    min-height: 100%;
    overflow: hidden;
    text-align: center;
  }

  .heart {
    position: relative;
  }

  .heart:before,
  .heart:after {
    position: absolute;
    content: "";
    left: 18px;
    top: 0;
    width: 18px;
    height: 30px;
    background: #cc2022;
    border-radius: 30px 30px 0 0;
    transform: rotate(-45deg);
    transform-origin: 0 100%;
  }

  .heart:after {
    left: 0;
    transform: rotate(45deg);
    transform-origin: 100% 100%;
  }

  .cloud {
    position: absolute;
    -webkit-animation: moveclouds 15s linear infinite, sideWays 4s ease-in-out infinite alternate;
    animation: moveclouds 15s linear infinite, sideWays 4s ease-in-out infinite alternate;
    z-index: 9999;
  }

  .x1 {
    left: 5%;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0.6;
    -webkit-animation: moveclouds 15s linear infinite, sideWays 4s ease-in-out infinite alternate;
    -moz-animation: moveclouds 15s linear infinite, sideWays 4s ease-in-out infinite alternate;
  }

  .x2 {
    left: 25%;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0.9;
    -webkit-animation: moveclouds 25s linear infinite, sideWays 5s ease-in-out infinite alternate;
    -moz-animation: moveclouds 25s linear infinite, sideWays 5s ease-in-out infinite alternate;
  }

  .x3 {
    left: 55%;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.8;
    -webkit-animation: moveclouds 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
    -moz-animation: moveclouds 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
  }

  .x4 {
    left: 72%;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    transform: scale(0.75);
    opacity: 0.9;
    -webkit-animation: moveclouds 18s linear infinite, sideWays 2s ease-in-out infinite alternate;
    -moz-animation: moveclouds 18s linear infinite, sideWays 0zs ease-in-out infinite alternate;
  }

  .x5 {
    left: 88%;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.3;
    -webkit-animation: moveclouds 7s linear infinite, sideWays 1s ease-in-out infinite alternate;
    -moz-animation: moveclouds 7s linear infinite, sideWays 1s ease-in-out infinite alternate;
  }

  .x6 {
    left: 33%;
    opacity: 1;
    -webkit-animation: moveclouds 2s linear infinite, swayWays 1s ease-in-out infinite alternate;
    animation: moveclouds 10s linear infinite, swayWays 9s ease-in-out infinite alternate;
  }

  @keyframes moveclouds {
    0% {
      top: 800px;
    }
    100% {
      top: -500px;
    }
  }

  @keyframes sideWays {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: 50px;
    }
  }

  @keyframes swayWays {
    0% {
      transform: rotate(12deg);
      left: -0.3%;
    }
    33% {
      transform: rotate(-2deg);
      left: 0.7%;
    }
    100% {
      transform: rotate(0deg);
      left: -12%;
    }
  }
`;

const HeartBubblies = () => {
  return (
    <div className="wrapper" css={cloudStyle}>
      <div className="heart x1 cloud" />
      <div className="heart x2 cloud" />
      <div className="heart x3 cloud" />
      <div className="heart x4 cloud" />
      <div className="heart x5 cloud" />
      <div className="heart x6 cloud" />
    </div>
  );
};

export default HeartBubblies;
