import { isBubbaloo, isCroydon, isKlook, isNiu, isTrendi } from "./applicationSlug";
import goamaDefaultAvatar from "assets/images/man.png";
import Avatar1 from "assets/images/Avatars-01.png";
import Avatar2 from "assets/images/Avatars-02.png";
import Avatar3 from "assets/images/Avatars-03.png";
import Avatar4 from "assets/images/Avatars-04.png";
import Avatar5 from "assets/images/Avatars-05.png";
import Avatar6 from "assets/images/Avatars-06.png";
import Avatar7 from "assets/images/Avatars-07.png";
import Avatar8 from "assets/images/Avatars-08.png";
import Avatar9 from "assets/images/Avatars-09.png";
import Avatar10 from "assets/images/Avatars-10.png";
import Avatar11 from "assets/images/Avatars-11.png";
import Avatar12 from "assets/images/Avatars-12.png";
import Avatar13 from "assets/images/Avatars-13.png";
import Avatar14 from "assets/images/Avatars-14.png";
import Avatar15 from "assets/images/Avatars-15.png";
import Avatar16 from "assets/images/Avatars-16.png";
import Avatar17 from "assets/images/Avatars-17.png";
import Avatar18 from "assets/images/Avatars-18.png";
import Avatar19 from "assets/images/Avatars-19.png";
import Avatar20 from "assets/images/Avatars-20.png";
import Avatar21 from "assets/images/Avatars-21.png";
import Avatar22 from "assets/images/Avatars-22.png";
import Avatar23 from "assets/images/Avatars-23.png";
import Avatar24 from "assets/images/Avatars-24.png";
import Avatar25 from "assets/images/Avatars-25.png";
import Avatar26 from "assets/images/Avatars-26.png";
import Avatar27 from "assets/images/Avatars-27.png";
import Avatar28 from "assets/images/Avatars-28.png";
import Avatar29 from "assets/images/Avatars-29.png";
import Avatar30 from "assets/images/Avatars-30.png";
import Avatar31 from "assets/images/Avatars-31.png";
import BubbalooAvatar02 from "assets/clients/bubbaloo/avatars/02.png";
import BubbalooAvatar01 from "assets/clients/bubbaloo/avatars/01.png";
import BubbalooAvatar03 from "assets/clients/bubbaloo/avatars/03.png";
import BubbalooAvatar04 from "assets/clients/bubbaloo/avatars/04.png";
import BubbalooAvatar05 from "assets/clients/bubbaloo/avatars/05.png";
import BubbalooAvatar06 from "assets/clients/bubbaloo/avatars/06.png";
import BubbalooAvatar07 from "assets/clients/bubbaloo/avatars/07.png";
import BubbalooAvatar08 from "assets/clients/bubbaloo/avatars/08.png";
import BubbalooAvatar09 from "assets/clients/bubbaloo/avatars/09.png";
import BubbalooAvatar10 from "assets/clients/bubbaloo/avatars/10.png";
import BubbalooAvatar11 from "assets/clients/bubbaloo/avatars/11.png";
import BubbalooAvatar12 from "assets/clients/bubbaloo/avatars/12.png";
import BubbalooAvatar13 from "assets/clients/bubbaloo/avatars/13.png";
import BubbalooAvatar14 from "assets/clients/bubbaloo/avatars/14.png";
import BubbalooAvatar15 from "assets/clients/bubbaloo/avatars/15.png";
import BubbalooAvatar16 from "assets/clients/bubbaloo/avatars/16.png";
import BubbalooAvatar17 from "assets/clients/bubbaloo/avatars/17.png";
import BubbalooAvatar18 from "assets/clients/bubbaloo/avatars/18.png";
import CroydonAvatar01 from "assets/clients/Croydon/Avatars/01.png";
import CroydonAvatar02 from "assets/clients/Croydon/Avatars/02.png";
import CroydonAvatar03 from "assets/clients/Croydon/Avatars/03.png";
import CroydonAvatar04 from "assets/clients/Croydon/Avatars/04.png";
import CroydonAvatar05 from "assets/clients/Croydon/Avatars/05.png";
import CroydonAvatar06 from "assets/clients/Croydon/Avatars/06.png";
import CroydonAvatar07 from "assets/clients/Croydon/Avatars/07.png";
import CroydonAvatar08 from "assets/clients/Croydon/Avatars/08.png";
import CroydonAvatar09 from "assets/clients/Croydon/Avatars/09.png";
import CroydonAvatar10 from "assets/clients/Croydon/Avatars/10.png";
import NiuAvatar01 from "assets/clients/Niu/Avatars/01.png";
import NiuAvatar02 from "assets/clients/Niu/Avatars/02.png";
import NiuAvatar03 from "assets/clients/Niu/Avatars/03.png";
import NiuAvatar04 from "assets/clients/Niu/Avatars/04.png";
import NiuAvatar05 from "assets/clients/Niu/Avatars/05.png";
import NiuAvatar06 from "assets/clients/Niu/Avatars/06.png";
import NiuAvatar07 from "assets/clients/Niu/Avatars/07.png";
import NiuAvatar08 from "assets/clients/Niu/Avatars/08.png";
import NiuAvatar09 from "assets/clients/Niu/Avatars/09.png";
import NiuAvatar10 from "assets/clients/Niu/Avatars/10.png";
import NiuAvatar11 from "assets/clients/Niu/Avatars/11.png";
import NiuAvatar12 from "assets/clients/Niu/Avatars/12.png";

const goamaAvatars = [
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
  Avatar14,
  Avatar15,
];

const bubbalooAvatars = [
  BubbalooAvatar01,
  BubbalooAvatar02,
  BubbalooAvatar03,
  BubbalooAvatar04,
  BubbalooAvatar05,
  BubbalooAvatar06,
  BubbalooAvatar07,
  BubbalooAvatar08,
  BubbalooAvatar09,
  BubbalooAvatar10,
  BubbalooAvatar11,
  BubbalooAvatar12,
  BubbalooAvatar13,
  BubbalooAvatar14,
  BubbalooAvatar15,
  BubbalooAvatar16,
  BubbalooAvatar17,
  BubbalooAvatar18,
];

const croydonAvatars = [
  CroydonAvatar01,
  CroydonAvatar02,
  CroydonAvatar03,
  CroydonAvatar04,
  CroydonAvatar05,
  CroydonAvatar06,
  CroydonAvatar07,
  CroydonAvatar08,
  CroydonAvatar09,
  CroydonAvatar10,
];

const klookAvatars = [Avatar26, Avatar27, Avatar28, Avatar29, Avatar30, Avatar31];

const niuAvatars = [
  NiuAvatar01,
  NiuAvatar02,
  NiuAvatar03,
  NiuAvatar04,
  NiuAvatar05,
  NiuAvatar06,
  NiuAvatar07,
  NiuAvatar08,
  NiuAvatar09,
  NiuAvatar10,
  NiuAvatar11,
  NiuAvatar12,
];

export const trendiAvatars = [
  Avatar16,
  Avatar17,
  Avatar18,
  Avatar19,
  Avatar20,
  Avatar21,
  Avatar22,
  Avatar23,
  Avatar24,
  Avatar25,
];

const setAvatars = () => {
  if (isBubbaloo) return bubbalooAvatars;
  else if (isCroydon) return croydonAvatars;
  else if (isKlook) return klookAvatars;
  else if (isNiu) return niuAvatars;
  else if (isTrendi) return trendiAvatars;
  else return goamaAvatars;
};

const setDefaultAvatar = () => {
  if (isBubbaloo) return BubbalooAvatar01;
  else if (isCroydon) return CroydonAvatar01;
  else if (isNiu) return NiuAvatar01;
  else return goamaDefaultAvatar;
};

export const avatars = setAvatars();
export const defaultAvatar = setDefaultAvatar();

export const isValidProfilePicId = (id: number | undefined) => {
  if (id === undefined) return false;

  return id >= 0 && id <= avatars.length - 1;
};
