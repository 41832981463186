import { createSlice } from "@reduxjs/toolkit";
import api from "actions/api";

// import tournamentsAPI from "actions/api/tournaments";
import Store, { AppDispatch } from "redux/Store";
import guestStorage from "utils/guestStorage";

export interface TournamentRankingState {
  error: boolean;
  loading: boolean;
  tournamentRanks: any;
  userRanks: any;
}

const initialState: TournamentRankingState = {
  error: false,
  loading: false,
  tournamentRanks: {},
  userRanks: {}
};

const TournamentRankingSlice = createSlice({
  name: "ranking",
  initialState,
  reducers: {
    fetchRankingsLoading(state) {
      state.loading = true;
    },

    fetchTournamentRankingsSuccess(state, action) {
      const { data, tournamentId, isGuestUserEnabled } = action.payload;

      if (!isGuestUserEnabled) {
        state.tournamentRanks = {
          ...state.tournamentRanks,

          [tournamentId]: data
        };
      } else {
        // Add guest user score to ranking if found in SessionStorage

        const { rankings, userRank } = guestStorage.getRankingsFromMaxScore(
          tournamentId,
          data
        );

        // Add guest user userRank if available

        if (userRank) {
          state.userRanks = {
            ...state.userRanks,

            [tournamentId]: userRank
          };
        }

        state.tournamentRanks = {
          ...state.tournamentRanks,

          [tournamentId]: rankings
        };
      }
      state.loading = false;
      state.error = false;
    },

    fetchUserRankingSuccess(state, action) {
      state.userRanks = {
        ...state.userRanks,
        [action.payload.tournamentId]: action.payload.data
      };
      state.loading = false;
      state.error = false;
    },

    fetchUserRankingError(state, action) {
      const { tournamentId = null } = action.payload || {};
      if (tournamentId) {
        state.userRanks = {
          ...state.userRanks,
          [tournamentId]: null
        };
      }
      state.loading = false;
      state.error = true;
    },

    fetchTournamentRankingsError(state) {
      state.tournamentRanks = [];
      state.loading = false;
      state.error = true;
    }
  }
});

export const {
  fetchRankingsLoading,
  fetchTournamentRankingsSuccess,
  fetchUserRankingSuccess,
  fetchUserRankingError,
  fetchTournamentRankingsError
} = TournamentRankingSlice.actions;

export default TournamentRankingSlice.reducer;

export const fetchTournamentRanking =
  (tournamentId: number, isGuestUserEnabled = false) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchRankingsLoading());
    return api
      .get(`tournaments/${tournamentId}/ranking/`)
      .then(response => {
        dispatch(
          fetchTournamentRankingsSuccess({
            data: response.data,
            tournamentId,
            isGuestUserEnabled
          })
        );
        return response.data;
      })
      .catch(() => dispatch(fetchTournamentRankingsError()));
  };

export const fetchUserRanking =
  (tournamentId: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchRankingsLoading());
    const state = Store.getState();
    const { max_scores } = state.maxScore;

    return api
      .post(`tournaments/${tournamentId}/user-rank/`, {
        "highest-score": tournamentId && max_scores[tournamentId]
      })
      .then(response => {
        dispatch(
          fetchUserRankingSuccess({ data: response.data, tournamentId })
        );
        return response.data;
      })
      .catch(() => dispatch(fetchUserRankingError(tournamentId)));
  };
