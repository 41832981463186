import { decodeDataFromURL, isStringEqual } from "utils";
import eventEmitter from "utils/eventEmitter";
import React, { useCallback, memo, useEffect, useRef, useState } from "react";
import { useAppSelector } from "redux/Store";

interface IAdvertisement {
  id: any;
  unit_code: string;
  position: {
    name: string;
    slug: string;
  };
  tournament: number;
}

interface ITournamentProps {
  name: any;
  tournament?: ITournament;
  game?: any;
  className?: string;
  isDesktop?: boolean;
  onAdvertFound?: Function;
}

// GPT.enableSingleRequest();

/**
 * Checks if a slot with a specific div ID is already defined in googletag.
 * @param {string} divId - The ID of the div associated with the ad slot.
 * @return {boolean} - Returns true if the slot is already defined, false otherwise.
 */
function isSlotAlreadyDefined(divId: string) {
  if (window.googletag?.pubads && window.googletag?.pubads().getSlots) {
    const slots = window.googletag?.pubads().getSlots();
    for (let i = 0; i < slots.length; i++) {
      if (slots[i].getSlotElementId() === divId) {
        return true;
      }
    }
  }
  return false;
}

const isAdInterstitial = (advert: any) => {
  return (
    advert?.position?.name === "Interstitial Ad After Game over" ||
    advert?.position?.name === "Interstitial Ad Before Game Play" ||
    isStringEqual(advert?.position?.name, "INTERSTITIAL")
  );
};

const isAdRewarded = (advert: any) => {
  return advert?.position?.name === "Rewarded Ad";
};

const getArrayOfSizes = (advert: any) => {
  return (
    advert?.position?.sizes?.map((size: any) => {
      if (size?.width && size.height) {
        return [size.width, size.height];
      }
    }) || []
  );
};

const Advertisement = memo(function Advertisement({ name, tournament, game, onAdvertFound }: ITournamentProps) {
  const { data, tournamentAdverts, c2pGameAdvert } = useAppSelector((state: any) => state.advertisements);
  const { partner_id, sub_partner_id } = decodeDataFromURL();

  const [advert, setAdvert] = useState<any>(null);
  // const [adSlot, setAdSlot] = useState<any>(null);
  const advertRef = useRef(null);

  const isMounted = useRef(false);

  useEffect(() => {
    // Component is mounted.
    isMounted.current = true;

    // Your logic here.

    return () => {
      // Cleanup function runs when the component is unmounted.
      isMounted.current = false;
    };
  }, []);

  const getAdvert = useCallback(() => {
    let foundAdvert = null;

    if (name === "Leaderboard" || name === "In Game") {
      if (tournament) {
        foundAdvert = tournamentAdverts[tournament?.id]?.advertisements?.find(
          (p: IAdvertisement) => p.position.name === name,
        );
      }
    } else if (name === "Arcade In Game") {
      foundAdvert = c2pGameAdvert[game?.slug]?.advertisements?.find((p: IAdvertisement) => p.position.name === name);
    } else if (name === "Tournament Game Over") {
      if (tournament) {
        foundAdvert = tournamentAdverts[tournament?.id]?.advertisements?.find(
          (p: IAdvertisement) => p.tournament === tournament?.id,
        );
        if (!foundAdvert) {
          foundAdvert = data?.find((p: IAdvertisement) => p.position.name === name);
        }
      }
    } else {
      foundAdvert = data?.find((p: IAdvertisement) => p.position.name === name);
    }
    return foundAdvert;
  }, [c2pGameAdvert, data, game?.slug, name, tournament, tournamentAdverts]);

  useEffect(() => {
    if (name) {
      const advert = getAdvert();
      setAdvert(advert);
      if (advert) {
        onAdvertFound && onAdvertFound();
      }
    }
  }, [getAdvert, name, onAdvertFound]);

  useEffect(() => {
    if (advert) {
      const existingScript = document.getElementById("gpt");
      if (!existingScript) {
        const gptScript = document.createElement("script");
        gptScript.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
        gptScript.async = false;
        gptScript.id = "gpt";
        document.head.appendChild(gptScript);
        gptScript.addEventListener("load", () => {
          window.googletag = window.googletag || { cmd: [] };
        });
        gptScript.onload = () => {
          // !slot && callback();
        };
      }
    }
  }, [advert]);

  useEffect(() => {
    const advertRefCurrent = advertRef.current;
    const isInterstitial = isAdInterstitial(advert);
    const isRewarded = isAdRewarded(advert);
    let interstitialSlot: any = null;
    let rewardedSlot: any = null;
    let rewardPayload: any = null;
    let adSlot: any = null;
    // eslint-disable-next-line prefer-const
    let adUnitPath = advert?.ad_unit_path;
    if (isInterstitial) {
      // adUnitPath = "/22022010600/test_interstitial"; // TODO: REMOVE
      adUnitPath = advert?.ad_unit_path; // TODO: REMOVE
    }
    if (isRewarded) {
      adUnitPath = advert?.ad_unit_path; // TODO: REMOVE
      // adUnitPath = "/22022010600/Rewarded_test"; // TODO: REMOVE
      // adUnitPath = "/22639388115/rewarded_web_example"; // TODO: REMOVE
    }
    if (
      (advert && advertRefCurrent && !isSlotAlreadyDefined(adUnitPath) && adUnitPath) ||
      (advert && adUnitPath && (isInterstitial || isRewarded))
    ) {
      window.googletag = window.googletag || { cmd: [] };
      const googletag = window.googletag;

      googletag?.cmd.push(() => {
        if (document.getElementById(adUnitPath) || isInterstitial || isRewarded) {
          // Configure all ad slots on the page to be expanded by default, but
          // collapse slots that are unable to be filled with an ad.
          googletag.pubads().collapseEmptyDivs();

          if (isInterstitial) {
            interstitialSlot = googletag.defineOutOfPageSlot(adUnitPath, googletag.enums.OutOfPageFormat.INTERSTITIAL);
            // Slot returns null if the page or device does not support interstitials.
            if (interstitialSlot) {
              interstitialSlot.addService(googletag.pubads());
              googletag.display(interstitialSlot);
              googletag.pubads().addEventListener("slotOnload", (event: any) => {
                if (interstitialSlot === event.slot) {
                  // console.log("Interstitial ad loaded successfully.");
                }
              });
              googletag.pubads().addEventListener("slotRenderEnded", function (event: any) {
                if (event.isEmpty) {
                  // console.log("Ad slot is empty. No ad was fetched.");
                } else {
                  // console.log("Ad slot rendered successfully.");
                }
              });
            }
          } else if (isRewarded) {
            if (!isMounted.current) return;
            rewardedSlot = googletag.defineOutOfPageSlot(adUnitPath, googletag.enums.OutOfPageFormat.REWARDED);
            if (rewardedSlot) {
              rewardedSlot.addService(googletag.pubads());
              googletag.pubads().addEventListener("rewardedSlotReady", (event: any) => {
                if (!isMounted.current) return;
                eventEmitter.emit("READY_REWARDED_AD", {});
                eventEmitter.on("SHOW_REWARDED_AD", () => {
                  event.makeRewardedVisible();
                  eventEmitter.off("SHOW_REWARDED_AD");
                });
                eventEmitter.on("DESTROY_REWARDED_AD", () => {
                  eventEmitter.off("DESTROY_REWARDED_AD");
                  window?.googletag?.destroySlots([rewardedSlot]);
                });
              });
              googletag.pubads().addEventListener("rewardedSlotClosed", () => {
                if (!isMounted.current) return;
                eventEmitter.off("SHOW_REWARDED_AD");
                if (!rewardPayload) {
                  eventEmitter.emit("HIDE_REWARDED_AD", {});
                } else {
                  eventEmitter.emit("CLAIM_REWARDED_AD", { reward: rewardPayload });
                }
                window?.googletag?.destroySlots([rewardedSlot]);
              });

              googletag.pubads().addEventListener("rewardedSlotGranted", (event: any) => {
                if (!isMounted.current) return;
                // Automatically close the ad by destroying the slot.
                // Remove this to force the user to close the ad themselves.
                // dismissRewardedAd();
                const reward = event.payload;
                if (reward) {
                  rewardPayload = reward;
                  //   displayModal(
                  //       'grant', `You have been rewarded ${reward.amount} ${reward.type}!`);
                } else {
                  // eventEmitter.emit("SKIP_REWARDED_AD", {});
                }
              });
            }
          } else {
            const sizeArr = getArrayOfSizes(advert);
            const responsiveAdSlot = googletag
              ?.defineSlot(adUnitPath, sizeArr, adUnitPath)
              ?.addService(googletag.pubads());

            const mapping = googletag
              ?.sizeMapping([1024, 768], sizeArr)
              ?.addSize([640, 480], sizeArr)
              ?.addSize([0, 0], sizeArr)
              ?.build();
            responsiveAdSlot?.defineSizeMapping(mapping);
            adSlot = responsiveAdSlot;
          }
          // Enable SRA and services.
          googletag?.pubads()?.enableSingleRequest();
          if (partner_id && sub_partner_id) {
            googletag?.pubads()?.setTargeting("partner_id", partner_id);
            googletag?.pubads()?.setTargeting("subpartner_id", sub_partner_id);
          }

          googletag?.enableServices();
          if (rewardedSlot) {
            googletag?.display(rewardedSlot);
          }
        }
      });

      if (advert && interstitialSlot) {
        // googletag.display(interstitialSlot);
        // eventEmitter.on("SHOW_INTERSTITIAL_AD", () => {
        // googletag.pubads().refresh([interstitialSlot]);
        googletag?.cmd.push(() => googletag.display(interstitialSlot));
        // eventEmitter.off("SHOW_INTERSTITIAL_AD");
        // });
      } else if (
        advert &&
        document.getElementById(adUnitPath) &&
        document.getElementById(adUnitPath)?.offsetWidth !== 0
      ) {
        googletag?.cmd.push(() => googletag.display(adUnitPath));
      }
    }
    // let interval: any = null;
    // if (adSlot) {
    //   interval = setInterval(() => {
    //     window.googletag?.cmd.push(() => {
    //       window.googletag?.pubads().refresh([adSlot]);
    //     });
    //   }, 60000);
    // }

    //  Cleanup: If you decide to destroy the slots, you can do so here.
    return () => {
      if (rewardedSlot) {
        window?.googletag?.destroySlots([rewardedSlot]);
      }
      if (advertRefCurrent && window?.googletag?.destroySlots && adSlot) {
        // interval && clearInterval(interval);
        window?.googletag?.destroySlots([adSlot]);
      }
    };
  }, [advert, partner_id, sub_partner_id]);

  if (!advert && isAdInterstitial(advert) && isAdRewarded(advert)) return null;

  return (
    <div
      ref={advertRef}
      id={advert?.ad_unit_path}
      data-full-width-responsive="true"
      style={{ width: "100%", minWidth: 250, height: "100%", textAlign: "center" }}
    />
  );
});

export default Advertisement;
